<template>
  <div v-if="getTimezoneDone">
    <div class="analytic-container">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h1 class="analytic-heading">Estimated Profit</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="showRegenerate">
            <v-btn class="ml-auto" color="primary" @click="doCalculate"> Recalculate </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showExport">
          <v-col cols="12">
            <v-btn :loading="isLoadingReport" outlined large color="primary" @click="exportCsv">
              <v-icon left>mdi-file-excel</v-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-menu
          v-if="showDatePicker"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">{{ dates ? dateRangeText : 'Choose time range' }}</v-btn>
          </template>
          <v-date-picker v-model="dates" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers_first" :items="items0" class="elevation-1" hide-default-footer>
              <template v-slot:[`item.revenue`]="{ item }">{{ `$${item.revenue}` }}</template>
              <template v-slot:[`item.basecost`]="{ item }">{{ `$${item.basecost}` }}</template>
              <template v-slot:[`item.handling`]="{ item }">{{ `$${item.handling}` }}</template>
              <template v-slot:[`item.dispute`]="{ item }">{{ `$${item.dispute}` }}</template>
              <template v-slot:[`item.profit`]="{ item }">{{ `$${item.profit}` }}</template>
              <template v-slot:[`item.hold`]="{ item }">{{ `$${item.hold}` }}</template>
              <template v-slot:[`item.cr`]="{ item }">{{ `${item.cr}%` }}</template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items1"
              class="elevation-1"
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:header.productsWithoutBasecost="{}">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">PWB</span>
                  </template>
                  <span>Number of products without basecost</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.revenue`]="{ item }">{{ `$${item.revenue}` }}</template>
              <template v-slot:[`item.basecost`]="{ item }">{{ `$${item.basecost}` }}</template>
              <template v-slot:[`item.handling`]="{ item }">{{ `$${item.handling}` }}</template>
              <template v-slot:[`item.dispute`]="{ item }">{{ `$${item.dispute}` }}</template>
              <template v-slot:[`item.profit`]="{ item }">{{ `$${item.profit}` }}</template>
              <template v-slot:[`item.hold`]="{ item }">{{ `$${item.hold}` }}</template>
              <template v-slot:[`item.cr`]="{ item }">{{ `${item.cr}%` }}</template>
              <template v-slot:[`item.status`]="{ item }">
                <v-tooltip top v-if="item.isApproved">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="status approved">Approved</span>
                  </template>
                  <span>Profits and fees are approved and added to your balance.</span>
                </v-tooltip>
                <v-tooltip top v-if="!item.isApproved">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="status pending">Estimation</span>
                  </template>
                  <span>Estimation values in need of approval. The profits and fees may change after approval</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.productsWithoutBasecost`]="{ item }">
                {{ getPWB(item.missingBaseCostProducts) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y v-if="!item.isFirstRow">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-dots-vertical </v-icon>
                  </template>

                  <v-list nav dense class="list-action">
                    <v-list-item @click="doCalculate(item._id.x)" :disabled="item.isApproved">
                      <v-list-item-title>
                        <v-icon>mdi-reload</v-icon>
                        <span>Recalculate</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="openPWB(item)"
                      :disabled="item.isApproved"
                      v-if="isLatteAccount && !item.isFirstRow"
                    >
                      <v-list-item-title>
                        <v-icon> mdi-playlist-edit </v-icon>
                        <span>List product without basecost</span>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- <v-list-item :disabled="item.isApproved" @click="approveItem(item)">
                      <v-list-item-title>
                        <v-icon> mdi-check </v-icon>
                        <span>Approve</span>
                      </v-list-item-title>
                    </v-list-item> -->

                    <v-list-item @click="openDetailDialog(item)">
                      <v-list-item-title>
                        <v-icon> mdi-clipboard-list-outline </v-icon>
                        <span>Detail</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- <v-tooltip top v-if="!isLatteAccount && !item.isFirstRow">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="openDetailDialog(item)">
                      mdi-clipboard-list-outline
                    </v-icon>
                  </template>
                  <span>Detail</span>
                </v-tooltip> -->
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <ListProductWithoutBasecost ref="listProductWithoutBasecost" />
    <DetailDialog ref="detailDialog" />
  </div>
  <div v-else class="analytic-loading">
    <Loading />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';
import moment from 'moment';
import analyticRequest from '@/apis/request/analyticRequest';
import ListProductWithoutBasecost from '@/views/website/estimated-profit/components/ListProductWithoutBasecost.vue';
import DetailDialog from '@/views/website/estimated-profit/components/DetailDialog.vue';

export default {
  components: {
    DetailDialog,
    ListProductWithoutBasecost,
    Loading,
  },
  data: function() {
    let query = this.$route.query;
    return {
      isLoadingReport: false,
      stores: query?.stores?.split(',') || [],
      getTimezoneDone: true,
      search: query.search || '',
      productHasSale: query?.sales || 'true',
      filter: query.filter || 'SHOP',
      dateRange: {
        from: null,
        to: null,
      },
      headers_first: [
        {
          text: 'Revenue',
          align: 'start',
          sortable: false,
          value: 'revenue',
        },
        {
          text: 'Basecost',
          align: 'start',
          sortable: false,
          value: 'basecost',
        },
        {
          text: 'Handling',
          align: 'start',
          sortable: false,
          value: 'handling',
        },
        {
          text: 'Dispute',
          align: 'start',
          sortable: false,
          value: 'dispute',
        },
        {
          text: 'Profit',
          align: 'start',
          sortable: false,
          value: 'profit',
        },
        {
          text: 'Hold',
          sortable: false,
          value: 'hold',
        },
        {
          text: 'Orders',
          align: 'start',
          sortable: false,
          value: 'orders',
        },
        {
          text: 'AOV',
          align: 'start',
          sortable: false,
          value: 'aov',
        },
      ],
      headers: [
        {
          text: 'Datetime',
          align: 'start',
          sortable: false,
          value: '_id.x',
        },
        {
          text: 'Order',
          align: 'start',
          sortable: false,
          value: 'orders',
        },
        {
          text: 'Revenue',
          align: 'start',
          sortable: false,
          value: 'revenue',
        },
        {
          text: 'Basecost',
          align: 'start',
          sortable: false,
          value: 'basecost',
        },
        {
          text: 'Handling',
          align: 'start',
          sortable: false,
          value: 'handling',
        },
        {
          text: 'Dispute',
          align: 'start',
          sortable: false,
          value: 'dispute',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Profit',
          align: 'start',
          sortable: false,
          value: 'profit',
        },
        {
          text: 'Hold',
          align: 'start',
          sortable: false,
          value: 'hold',
        },
        {
          text: 'Products without basecost',
          align: 'start',
          sortable: false,
          value: 'productsWithoutBasecost',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      items0: [],
      items1: [],
      dates: ['2019-09-10', '2019-09-20'],
      showExport: false,
      showDatePicker: false,
      showRegenerate: false,
      isLatteAccount: false,
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  async created() {
    if (localStorage.getItem('dcomcy-user')) {
      let u = JSON.parse(localStorage.getItem('dcomcy-user'));
      if (
        u?.email?.indexOf('@hiweb.io') > -1 ||
        u?.email?.indexOf('@lattehub.com') > -1 ||
        u?.email?.indexOf('ketoan@dcomcy.com') > -1
      ) {
        this.showRegenerate = true;
        this.isLatteAccount = true;
      }
    }
    await this.getEstimatedProfitData();
  },
  methods: {
    async getEstimatedProfitData() {
      let data = await analyticRequest.get(`/actions/estimated_profit`, {
        params: {
          timezone: this.pagePreference.standardTimezone || 'America/Los_Angeles',
        },
      });

      if (data?.data?.firstRow) this.items0 = [data?.data?.firstRow];

      if (data?.data?.sumerized) {
        this.items1 = data?.data?.sumerized;
        if (this.items1.length) {
          this.items1[0].isFirstRow = true;
        }
      }
    },

    onSelectedStores(stores) {
      this.stores = stores;
    },
    pickedDate(dates) {
      this.dateRange = {
        from: dates.startDate,
        to: dates.endDate,
      };
    },
    async doCalculate(x = '') {
      let mytimezone = encodeURIComponent(this.pagePreference.standardTimezone || 'America/Los_Angeles');
      let data = await analyticRequest.post(`/actions/regenate_profit?timezone=${mytimezone}`, x ? { x } : {});
    },
    async exportCsv() {
      try {
        this.isLoadingReport = true;
        let query = this.$route.query;
        const url = 'products/sale-report/export-excel';

        let data = await analyticRequest.get(url, { params: query });
        let text = data.data;
        console.log(text);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'sale-report.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (error) {
        console.log(error);
      }
      this.isLoadingReport = false;
    },
    getPWB(list) {
      if (!list?.length) return 0;
      return list.length;
    },
    openPWB(item) {
      this.$refs.listProductWithoutBasecost.open(item.missingBaseCostProducts);
    },
    async approveItem(item) {
      try {
        let mytimezone = encodeURIComponent(this.pagePreference.standardTimezone || 'America/Los_Angeles');
        let data = await analyticRequest.post(`/actions/estimated_profit/approve?timezone=${mytimezone}`, {
          x: item._id.x,
        });
        this.getEstimatedProfitData();
      } catch (e) {
        console.log(e);
      }
    },
    openDetailDialog(item) {
      this.$refs.detailDialog.open(item);
    },
  },
  computed: {
    allStores() {
      return this.$store.getters.stores;
    },
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  watch: {
    filter: function(val) {},
    productHasSale: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: query?.size,
          page: query?.page,
          stores: query?.stores,
          search: query?.search,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: val,
        },
      });
    },
    search: async function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: query?.stores,
          search: val,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
    stores: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: val?.join(','),
          search: query?.search,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
    dateRange: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: query?.stores,
          search: query?.search,
          from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
          to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
  },
  destroyed() {
    // No remove auto refresh
    localStorage.removeItem(STORAGE_NAME.SALE_REPORT_QUERY);
  },
};
</script>

<style lang="scss" scoped>
.website-page .website-container {
  max-width: unset !important;
}

.analytic-sale-report {
  text-align: center;
}

.select-filter {
  max-width: 200px;
  min-width: 200px;
}

.analytic-container .l-chart-container {
  .text-black {
    color: #000000;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-small {
    font-size: 12px;
  }

  .l-chart-title {
    font-size: 16px;
  }

  .sub-chart-container {
    height: 100%;
    padding: 16px;
  }

  .sub-chart-container {
    // transform: scale(1.01);

    // margin: -8px;
    .on-hover {
      transition: all 0.5s;
    }
  }
}

.v-text-field {
  margin-top: 0px !important;
}

.analytic-loading {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.v-list-item:hover {
  cursor: pointer;
  background: #f5f5f5;
}

.v-list-item .v-icon {
  margin-right: 8px;
}

.status {
  font-size: 11px;
  padding: 6px 4px;
  cursor: pointer;
  border-radius: 4px;
}

.approved {
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
}

.pending {
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
}
</style>
